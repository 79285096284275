<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="姓名:">
            <el-input type="text" v-model="selectForm.Name"></el-input>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <el-table-column label="记录日期" prop="RecordDate"> </el-table-column>
        <el-table-column label="名称" prop="Name"></el-table-column>
        <el-table-column label="性别" prop="Sex"></el-table-column>
        <el-table-column label="岗位" prop="Post"></el-table-column>
        <el-table-column label="联系电话" prop="MobilePhone"></el-table-column>
        <el-table-column label="年龄" prop="age"></el-table-column>
        <el-table-column label="学历" prop="Diploma"></el-table-column>
        <el-table-column label="专业" prop="ProfessionType"></el-table-column>
        <el-table-column label="工作年限" prop="workingTime"></el-table-column>
        <el-table-column label="跟踪状态" prop="TraceStatus"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata"></el-pagination>
    <el-dialog :title="operation ? '新增' : '查看'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
      <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="Name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别:" prop="Sex" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.Sex" placeholder="性别" style="width: 100%">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系电话:" prop="MobilePhone" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                <el-input v-model="SakaryForm.MobilePhone" maxlength="11" show-word-limit placeholder="请输入有效手机号" @blur="phonecheck"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄:" prop="age" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.age"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学历:" prop="Diploma" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.Diploma"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业:" prop="ProfessionType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.ProfessionType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工作年限:" prop="workingTime" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.workingTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="籍贯:" prop="NativePlace" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.NativePlace"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.Post"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跟踪状态:" prop="TraceStatus" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.TraceStatus" placeholder="跟踪状态" style="width: 100%">
                <el-option label="合适" value="合适"></el-option>
                <el-option label="不合适" value="不合适"></el-option>
                <el-option label="跟踪中" value="跟踪中"></el-option>
                <el-option label="储备" value="储备"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="text" v-model="SakaryForm.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files :files="SakaryForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>




    <el-dialog :title="operation2 ? '编辑' : '编辑'" :visible.sync="SakaryVisible1" v-model="SakaryVisible1" :close-on-click-modal="false">
      <el-form ref="SakaryForm1" :model="SakaryForm1" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="Name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别:" prop="Sex" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm1.Sex" placeholder="性别" style="width: 100%">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系电话:" prop="MobilePhone" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                <el-input v-model="SakaryForm1.MobilePhone" maxlength="11" show-word-limit placeholder="请输入有效手机号" @blur="phonecheck"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄:" prop="age" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.age"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学历:" prop="Diploma" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.Diploma"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业:" prop="ProfessionType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.ProfessionType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工作年限:" prop="workingTime" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.workingTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="籍贯:" prop="NativePlace" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.NativePlace"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm1.Post"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跟踪状态:" prop="TraceStatus" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm1.TraceStatus" placeholder="跟踪状态" style="width: 100%">
                <el-option label="合适" value="合适"></el-option>
                <el-option label="不合适" value="不合适"></el-option>
                <el-option label="跟踪中" value="跟踪中"></el-option>
                <el-option label="储备" value="储备"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="text" v-model="SakaryForm1.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files :files="SakaryForm1.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit1('SakaryForm1')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  RegisterGetInfo,
  UpdateContract,
  RegisterInfo,
  RegisterAdd,
  RegisterUpdateInfo,
} from "../../api/hr";
import UploadFiles from "../../components/UploadFiles";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UploadFiles },
  data() {
    return {
      searchVal: "",
      selectForm: {
        Name: "",
      },
      loading: true,
      choosedusers: [],
      CertTypeLists: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      SakaryVisible1: false,
      addUserVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation2: false, // true:新增, false:编辑
      operation1: true,
      buttonList: [],
      SakaryForm: {
        FileArry: [],
      },
      SakaryForm1: {
        FileArry: [],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        name: this.selectForm.Name,
      };
      console.log(para);
      RegisterGetInfo(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    phonecheck() {
      if (!validate.isMobile(this.SakaryForm.MobilePhone)) {
        this.$message({
          message: "该输入无效,请输入其他有效手机号",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm.MobilePhone = null;
        return;
      }
    },
    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      RegisterInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });
      this.operation = false;
      this.operation1 = false;
    },
    handleEdit() {//编辑
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      RegisterInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm1 = res.data.response;
        this.key += 1;
        this.SakaryVisible1 = true;
      });
      this.operation2 = true;
      this.operation1 = true;
    },//handleEdit
    handleAdd() {//新增
      (this.SakaryForm = {
        UserName: "",
        UserId: "",
        ConTypeId: "",
        ConTypeName: "",
        EndDate: "",
        ConDate: "",
        ConText: "",
        Remark: "",
        Type: "",
        FileArry: [],
        StartDate: "",
        NativePlace:"",
        Remark:"",
      }),
        // GetDicContractTypePageList().then((res) => {
        //   this.CertTypeLists = res.data.response.data;
        // });
      this.key += 1;
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
              RegisterAdd(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
            //   UpdateContract(para).then((res) => {
            //     if (res.data.success) {
            //       this.$message({
            //         message: "修改成功",
            //         type: "success",
            //         duration: 5000,
            //       });
            //       this.SakaryVisible = false;
            //       this.getData(false);
            //     } else {
            //       this.$message({
            //         message: res.data.msg,
            //         type: "error",
            //         duration: 5000,
            //       });
            //     }
            //   });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },

    onSubmit1(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm1);
              //新增
              RegisterUpdateInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "更新成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible1 = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

